import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioAlterarSenha from '../usuario/usuarioAlterarSenha';
import Dispositivo from '../dispositivo/dispositivo';
import DispositivoUsuarioNovo from '../dispositivoUsuario/dispositivoUsuarioNovo';

export default props => (
	<div style={{ paddingTop: 64 }}>
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/dispositivoUsuarioNovo' component={DispositivoUsuarioNovo} />
			<Route path='/usuarioAlterarSenha' component={UsuarioAlterarSenha} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);

import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setError, setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function initForm(registro = {}) {
	return {
        type: 'CONFIGURACAO_SET_REGISTRO',
        payload: {
			registro: registro
		}
    };
}

export function getDados(id_usuario) {

	return async (dispatch, getState) => {

		if (getState().dispositivo.dispositivoSelecionado) {
			axios.get(`${consts.API_URL}/growbox/configuracao?serie=${getState().dispositivo.dispositivoSelecionado.serie}`)
			.then(resp => {
				console.log(resp);
				dispatch({
					type: 'CONFIGURACAO_LISTADO',
					payload: resp
				});
			}).catch(e => {
				console.log(e);
				setError('Controladora não respondeu');
			});
		}
    };
	/*
	{
	ligarHora: "5:0"
desligarHora: "18:0"
diasVega: 0
diasFlora: 120
canalUm: 338
canalDois: 389
avisoAgua: 0
tempVento: 47
ventilacaoConstante: true
umidadeMinima: 0
umidadeMaxima: 0
}
*/
}

export function salvar(registro) {
    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/growbox/configuracao?serie=${getState().dispositivo.dispositivoSelecionado.serie}`, {
			...registro,
			ligarHora: registro.ligarHora && registro.ligarHora.ts ? `${new Date(DateFormat.getTimestampParaDataHora(registro.ligarHora.ts)).getHours()}:${new Date(DateFormat.getTimestampParaDataHora(registro.ligarHora.ts)).getMinutes()}` : `${new Date(registro.ligarHora).getHours()}:${new Date(registro.ligarHora).getMinutes()}`,
			desligarHora: registro.desligarHora && registro.desligarHora.ts ? `${new Date(DateFormat.getTimestampParaDataHora(registro.desligarHora.ts)).getHours()}:${new Date(DateFormat.getTimestampParaDataHora(registro.desligarHora.ts)).getMinutes()}` : `${new Date(registro.desligarHora).getHours()}:${new Date(registro.desligarHora).getMinutes()}`
		}).then(resp => {
			console.log(resp);
			setSuccess('Configuração atualizada com sucesso');
		}).catch(e => {
			console.log(e);
			setError('Controladora não respondeu');
		});
    };
}

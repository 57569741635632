import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AppBar,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
  IconButton,
  Badge,
  Dialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import InputMask from "react-input-mask";

import {
  Timer as TimerIcon,
  WatchLater as WatchLaterIcon,
  CalendarToday as CalendarTodayIcon,
  EventAvailable as EventAvailableIcon,
  WbSunny as WbSunnyIcon,
  InvertColors as InvertColorsIcon,
  Spa as SpaIcon,
  WbIncandescent as WbIncandescentIcon,
  Build as BuildIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  DonutSmall as DonutSmallIcon,
  BubbleChart as BubbleChartIcon,
  SignalWifi0Bar as SemInternetIcon,
  SignalWifi1Bar as SinalRuimInternetIcon,
  SignalWifi3Bar as SinalBomInternetIcon,
  SignalWifi4Bar as SinalExcelenteInternetIcon,
} from "@material-ui/icons";

import { ThemeProvider } from "@material-ui/styles";
// import GaugeChart from 'react-gauge-chart'
// import { Sunburst, RadialChart } from 'react-vis';
// import ReactApexChart from 'react-apexcharts'
// import RadialBarChart from 'react-radial-bar-chart';

import blue from "@material-ui/core/colors/blue";
import getTheme from "../common/template/getTheme";
import getStyles from "../common/template/getStyles";
import imagemFlora from "../assets/images/flora.png";
import imagemVega from "../assets/images/vega.png";

import DashboardCard from "../common/components/dashboardCard";
import RadialChart from "../common/components/radialChart";

import DataHoraForm from "../dataHora/dataHoraForm";
import ConfiguracaoForm from "../configuracao/configuracaoForm";
import ManualForm from "../manual/manualForm";
import { setModoTela, getDados } from "./dashboardActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
import { desconectar } from "../manual/manualActions";

class Dashboard extends Component {
  state = {
    linhaSelecionada: null,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.props.getListaDispositivo(this.props.sessao.id || 0);
    this.props.getDados();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dispositivoSelecionado) !==
        JSON.stringify(this.props.dispositivoSelecionado) &&
      prevProps.dispositivoSelecionado !== null
    )
      this.props.getDados();
  }
  getLocalStyles() {
    return {
      root: {
        padding: 10,
        paddingBottom: 100,
      },
    };
  }

  render() {
    let style = this.getLocalStyles();

    return (
      <React.Fragment>
        <CssBaseline />

        {this.props.modoTela == "dashboard" ? (
          <Grid container spacing={1} style={style.root}>
            <DashboardCard
              xs={6}
              xl={4}
              sm={4}
              md={3}
              lg={3}
              color="blue"
              icon={<WatchLaterIcon />}
              title="TEMPO ONLINE"
              subtitle={this.props.registro.tempo}
            />
            <DashboardCard
              xs={6}
              xl={4}
              sm={4}
              md={3}
              lg={3}
              color="orange"
              icon={<CalendarTodayIcon />}
              title="DATE E HORA"
              subtitle={this.props.registro.dataHora}
            />
            <DashboardCard
              xs={6}
              xl={4}
              sm={4}
              md={3}
              lg={3}
              color="darkGreen"
              icon={<EventAvailableIcon />}
              title="INÍCIO CULTIVO"
              subtitle={this.props.registro.dataHoraCultivo}
            />
            <DashboardCard
              xs={6}
              xl={4}
              sm={4}
              md={3}
              lg={3}
              color="lightblue"
              icon={<SpaIcon />}
              title={`FASE: ${
                this.props.registro.faseCultivo == 1 ? "FLORA" : "VEGA"
              }`}
              subtitle={`${this.props.registro.ordem}%`}
            />
            <DashboardCard
              xs={6}
              xl={4}
              sm={4}
              md={3}
              lg={3}
              color="green"
              icon={<WbSunnyIcon />}
              title="CLIMA"
              subtitle={`${this.props.registro.temperatura}°C, ${this.props.registro.umidade}%`}
            />
            <DashboardCard
              xs={6}
              xl={4}
              sm={4}
              md={3}
              lg={3}
              color="yellow"
              icon={
                !this.props.registro.wifi ? (
                  <SemInternetIcon />
                ) : this.props.registro.wifi == "Ruim" ? (
                  <SinalRuimInternetIcon />
                ) : this.props.registro.wifi == "Bom" ? (
                  <SinalBomInternetIcon />
                ) : (
                  <SinalExcelenteInternetIcon />
                )
              }
              title="SINAL WI-FI"
              subtitle={this.props.registro.wifi}
            />
            <DashboardCard
              xs={12}
              xl={6}
              sm={6}
              md={3}
              lg={3}
              color="red"
              icon={<DonutSmallIcon />}
              title="EXAUSTOR"
              subtitle={this.props.registro.ventStatus ? "Ligado" : "Desligado"}
            />
            <DashboardCard
              xs={12}
              xl={6}
              sm={6}
              md={3}
              lg={3}
              color="lightGreen"
              icon={<BubbleChartIcon />}
              title="CONCENTRAÇÃO DE CO²"
              subtitle={`${this.props.registro.co2}ppm`}
            />
            <DashboardCard
              xs={12}
              xl={12}
              sm={12}
              md={12}
              lg={12}
              color="purple"
              icon={<WbIncandescentIcon />}
              title="POTÊNCIA DOS LEDS"
              component={
                <>
                  <Grid
                    container
                    spacing={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <RadialChart
                      data={[
                        {
                          value: this.props.registro.canalUm,
                          color: "#1565c0",
                        },
                        {
                          value: this.props.registro.canalDois,
                          color: "#e53935",
                        },
                      ]}
                      dimension={
                        window.innerWidth <= 400 ? window.innerWidth * 0.7 : 400
                      }
                      component={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: window.innerWidth <= 400 ? 20 : 24,
                              fontWeight: "bold",
                              color: "#1565c0",
                            }}
                          >
                            {this.props.registro.canalUm}% Vega
                          </div>
                          <div
                            style={{
                              fontSize: window.innerWidth <= 400 ? 20 : 24,
                              fontWeight: "bold",
                              color: "#e53935",
                            }}
                          >
                            {this.props.registro.canalDois}% Flora
                          </div>
                          <img
                            src={
                              this.props.registro.faseCultivo == 1
                                ? imagemFlora
                                : imagemVega
                            }
                            style={{
                              height: window.innerWidth <= 400 ? 80 : 100,
                              marginBottom: 10,
                            }}
                          />
                        </div>
                      }
                    />
                  </Grid>
                  <FormLabel style={{ width: "100%", textAlign: "right" }}>
                    {this.props.registro.atualizado}
                  </FormLabel>
                </>
              }
            />
          </Grid>
        ) : this.props.modoTela == "dataHora" ? (
          <DataHoraForm />
        ) : this.props.modoTela == "configuracao" ? (
          <ConfiguracaoForm />
        ) : this.props.modoTela == "manual" ? (
          <ManualForm />
        ) : null}
        <BottomNavigation
          showLabels
          value={this.props.modoTela}
          onChange={(event, value) => {
            if (this.props.modoTela == "manual" && value != "manual") {
              this.props.desconectar();
            }
            this.props.setModoTela(value);
          }}
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            left: 0,
            clear: "both",
            height: 80,
          }}
        >
          <BottomNavigationAction
            label="Dashboard"
            value="dashboard"
            icon={<DashboardIcon />}
          />
          <BottomNavigationAction
            label="Data/Hora"
            value="dataHora"
            icon={<WatchLaterIcon />}
          />
          <BottomNavigationAction
            label="Configuração"
            value="configuracao"
            icon={<SettingsIcon />}
          />
          <BottomNavigationAction
            label="Manual"
            value="manual"
            icon={<BuildIcon />}
          />
        </BottomNavigation>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  modoTela: state.dashboard.modoTela,
  registro: state.dashboard.registro,
  dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      getDados,
      getListaDispositivo,
      desconectar,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

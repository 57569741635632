import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setError, setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function initForm(registro = {}) {
	return {
        type: 'DISPOSITIVO_USUARIO_SET_REGISTRO',
        payload: {
			registro: registro
		}
    };
}

export function adicionar(registro) {
    return async (dispatch, getState) => {

		axios.get(`${consts.API_URL}/dispositivo/buscarPeloSerial?serie=${registro.numero_serie}&id_tipo=2`)
		.then(resp => {
			let dispositivo = resp.data || null;

			if (!dispositivo) {
				setError('Dispositivo não encontrado')
			} else if (getState().auth.usuario.administrador) {
				//Se é administrador permite criar testes
				axios.put(`${consts.API_URL}/dispositivo`, {
					...dispositivo,
					id_usuario_administrador: getState().auth.usuario.id,
					nome_administrador: registro.nome_controladora || registro.numero_serie
				}).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
					window.location.href = window.location.href.replace('dispositivoUsuarioNovo', '')
				}).catch(e => {
					setErroAPI(e);
				});
			} else if (dispositivo.id_usuario) {
				setError('Dispositivo não está disponível');
			} else {
				axios.put(`${consts.API_URL}/dispositivo`, {
					...dispositivo,
					id_usuario: getState().auth.usuario.id,
					nome: registro.nome_controladora || registro.numero_serie
				}).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
					window.location.href = window.location.href.replace('dispositivoUsuarioNovo', '')
				}).catch(e => {
					setErroAPI(e);
				});
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

const INITIAL_STATE = {
	registro: {
		dataHora: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'DATAHORA_SET_REGISTRO':
            return { ...state, registro: action.payload.registro };

		case 'DATAHORA_LISTADO':

			let data = action.payload.data;

			let registro = null;
			if (data) {
				registro = {};

				//UTC
				let dataHora = data.datahora ? new Date((data.datahora + (3*3600)) * 1000) : null;
				registro.dataHora = dataHora
					? `${dataHora.getFullYear()}/${String(dataHora.getMonth() + 1).padStart(2, '0')}/${String(dataHora.getDate()).padStart(2, '0')} ${String(dataHora.getHours()).padStart(2, '0')}:${String(dataHora.getMinutes()).padStart(2, '0')}:${String(dataHora.getSeconds()).padStart(2, '0')}` : '';

				console.log(data);

				console.log(registro);

			}

			return {
				...state,
				registro: registro || INITIAL_STATE.registro
			};

        default:
            return state;
    }
}

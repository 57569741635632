import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	AppBar, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, Snackbar,
	IconButton, Badge, Dialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions, DialogContentText, DialogTitle, DialogContent, DialogActions,
	List, ListItem, ListItemAvatar, ListItemText, Divider, Card, CardContent, Paper, BottomNavigation, BottomNavigationAction, Slider, Switch,
	MobileStepper, makeStyles
} from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import {
	KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon, Check as CheckIcon
} from '@material-ui/icons';
import { Route } from 'react-router';
import LuxonUtils from '@date-io/luxon';
import DateFormat from '../common/dateFormat/DateFormat';
import imagemPasso1 from '../assets/images/manual/manual_passo_1.png';
import imagemPasso2 from '../assets/images/manual/manual_passo_2.png';
import imagemPasso3 from '../assets/images/manual/manual_passo_3.png';
import imagemPasso4 from '../assets/images/manual/manual_passo_4.png';
import imagemPasso5 from '../assets/images/manual/manual_passo_5.png';
import imagemPasso6 from '../assets/images/manual/manual_passo_6.png';
import imagemPasso7 from '../assets/images/manual/manual_passo_7.png';

import { initForm, adicionar } from './dispositivoUsuarioActions';

class DispositivoUsuarioNovo extends Component {

	state = {
		passoAtual: 0,
		dadosPassos: [{
			titulo: 'Primeiros Passos',
			imagem: imagemPasso1
		}, {
			titulo: 'Conectando a Growbox',
			imagem: imagemPasso2
		}, {
			titulo: 'Conectando a Growbox',
			imagem: imagemPasso3
		}, {
			titulo: 'Conectando a Growbox',
			imagem: imagemPasso4
		}, {
			titulo: 'Conectando a Growbox',
			imagem: imagemPasso5
		}, {
			titulo: 'Conectando a Growbox',
			imagem: imagemPasso6
		}, {
			titulo: 'Conectando a Growbox',
			imagem: imagemPasso7
		}]
	}

    componentWillMount() {

    }

	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100
			},
			gridInput: {
				marginTop: 16,
				paddingLeft: 16,
				paddingRight: 16
			},
			inputSlider: {
			},
			gridButton: {
				marginTop: 20
			}
		};
	}

    render() {

		let style = this.getLocalStyles();

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let dadosPasso = this.state.dadosPassos[this.state.passoAtual];

        return (
			<Grid container
				spacing={1}
				style={style.root}
				direction='column'
				justifyContent='center'
				alignItems='center' >

				<Grid container
					direction='row'
					justifyContent='space-between'
					xs={12} sm={8} md={6} lg={4} >

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='center' >

						<MobileStepper
							variant='dots'
							steps={8}
							color='secondary'
							position='static'
							activeStep={this.state.passoAtual}
							backButton={
								<Button size='small' color='secondary' onClick={() => this.setState({ ...this.state, passoAtual: this.state.passoAtual - 1 })} disabled={this.state.passoAtual === 0}>
									<KeyboardArrowLeftIcon /> Anterior
								</Button>
							}
							nextButton={
								<Button size='small' color='secondary' onClick={() => {
									if (this.state.passoAtual < 7) {
										this.setState({ ...this.state, passoAtual: this.state.passoAtual + 1 })
									} else {
										this.props.adicionar(this.props.registro);
									}
								}} disabled={this.state.passoAtual === 8}>
									{this.state.passoAtual <= 6 ? `Próximo` : `Finalizar`} {this.state.passoAtual <= 6 ? <KeyboardArrowRightIcon /> : <CheckIcon />}
								</Button>
							} />
					</Grid>
				</Grid>

				<Grid container
					direction='row'
					justifyContent='space-between'
					xs={12} sm={8} md={6} lg={4} >

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='center'
						alignItems='center' >

						{/*dadosPasso && dadosPasso.titulo ? (
							<Typography id='discrete-slider' gutterBottom variant='h4'>
								{dadosPasso.titulo}
							</Typography>
						) : null*/}

						{dadosPasso && dadosPasso.imagem ? (
							<img src={dadosPasso.imagem} style={{ width: '100%' }}/>
						) : (
							<Grid container
								direction='column'
								justifyContent='center' >
								<TextField
									id='standard-basic'
									margin='normal'
									label='Dê um nome para o seu Growbox'
									value={this.props.registro.nome_controladora}
									onChange={(data) => {
										this.props.initForm({
											...this.props.registro,
											nome_controladora: data.target.value
										});
									}} />

								<TextField
									id='standard-basic'
									margin='normal'
									label='Informe o número de série'
									value={this.props.registro.numero_serie}
									onChange={(data) => {
										this.props.initForm({
											...this.props.registro,
											numero_serie: data.target.value
										});
									}} />
							</Grid>
						)}
					</Grid>
				</Grid>
				{this.state.passoAtual <= 6 ? (
					<Grid container
						direction='row'
						justifyContent='space-between'
						xs={12} sm={8} md={6} lg={4} >

						<Grid container
							style={style.gridInput}
							direction='row'
							justifyContent='space-between' >

							<Button color='secondary'
								variant='outlined'
								onClick={() => this.setState({ ...this.state, passoAtual: 7 })} >
								Pular
							</Button>

							<Route render={({ history}) => (
								<Button color='secondary'
									variant='outlined'
									onClick={() => history.push('/')} >
									Cancelar
								</Button>
							)} />
						</Grid>
					</Grid>
				) : null}
			</Grid>
        )
    }


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.dispositivoUsuario.registro || {}
});
const mapDispatchToProps = dispatch => bindActionCreators({ initForm, adicionar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoUsuarioNovo);

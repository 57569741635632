import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AppBar,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
  IconButton,
  Badge,
  Dialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import DateFormat from "../common/dateFormat/DateFormat";

import { initForm, getDados, salvar } from "./dataHoraActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";

class DataHoraForm extends Component {
  async componentDidMount() {
    await this.props.getListaDispositivo(this.props.sessao.id || 0);
    this.props.getDados();
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dispositivoSelecionado) !==
      JSON.stringify(this.props.dispositivoSelecionado)
    )
      this.props.getDados();
  }

  getLocalStyles() {
    return {
      root: {
        padding: 10,
        paddingBottom: 100,
      },
      gridButton: {
        marginTop: 20,
      },
    };
  }

  render() {
    let style = this.getLocalStyles();

    let readOnly = this.props.excluir ? "readOnly" : "";

    return (
      <Grid
        container
        spacing={1}
        style={style.root}
        direction="column"
        justifyContent="center"
      >
        <Grid container direction="row" justifyContent="center">
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDateTimePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy HH:mm"
              margin="normal"
              id="date-picker-inline"
              label="Data"
              ampm={false}
              mask="__/__/____ __:__"
              value={this.props.registro.dataHora}
              onChange={(data) => {
                this.props.initForm({
                  ...this.props.registro,
                  dataHora: data,
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "Altera a data",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          container
          style={style.gridButton}
          direction="row"
          justifyContent="center"
        >
          <Button
            style={{ marginRight: 5 }}
            variant="outlined"
            color="primary"
            onClick={() => this.props.salvar(this.props.registro)}
            color="primary"
          >
            Salvar
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            variant="outlined"
            color="primary"
            href="#/"
            color="secondary"
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  registro: state.dataHora.registro || {},
  dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { initForm, getDados, getListaDispositivo, salvar },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DataHoraForm);

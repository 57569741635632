import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	AppBar, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, Snackbar,
	IconButton, Badge, Dialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions, DialogContentText, DialogTitle, DialogContent, DialogActions,
	List, ListItem, ListItemAvatar, ListItemText, Divider, Card, CardContent, Paper, BottomNavigation, BottomNavigationAction, Slider, Switch, makeStyles
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import DateFormat from '../common/dateFormat/DateFormat';

import { setModoTela } from '../dashboard/dashboardActions';
import { initForm, getDados, salvar } from './configuracaoActions';

class ConfiguracaoForm extends Component {

    componentWillMount() {
		setTimeout(() => {
			this.props.getDados();
		}, 1000);
    }

	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100
			},
			gridInput: {
				marginTop: 16,
				paddingLeft: 16,
				paddingRight: 16
			},
			inputSlider: {
			},
			gridButton: {
				marginTop: 20
			}
		};
	}

    render() {

		let style = this.getLocalStyles();

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<Grid container
				spacing={1}
				style={style.root}
				direction='column'
				justifyContent='center'
				alignItems='center' >

				<Grid container
					direction='row'
					justifyContent='left'
					xs={12} sm={12} md={10} lg={8} >

					<Grid container
						style={style.gridInput}
						direction='row'
						justifyContent='left' >

						<MuiPickersUtilsProvider utils={LuxonUtils}>
							<KeyboardTimePicker
								disableToolbar
								margin='normal'
								id='time-picker'
								label='Ligar'
								ampm={false}
								mask='__:__'
						        format='HH:mm'
						        value={this.props.registro.ligarHora}
						        onChange={(data) => {
									this.props.initForm({
										...this.props.registro,
										ligarHora: data
									});
								}}
								KeyboardButtonProps={{
									'aria-label': 'Altera a hora',
								}} />
						</MuiPickersUtilsProvider>

						<MuiPickersUtilsProvider utils={LuxonUtils}>
							<KeyboardTimePicker
								disableToolbar
								margin='normal'
								id='time-picker'
								label='Desligar'
								ampm={false}
								mask='__:__'
						        format='HH:mm'
						        value={this.props.registro.desligarHora}
						        onChange={(data) => {
									this.props.initForm({
										...this.props.registro,
										desligarHora: data
									});
								}}
								KeyboardButtonProps={{
									'aria-label': 'Altera a hora',
								}} />
						</MuiPickersUtilsProvider>
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Vega (dias)
						</Typography>
						<TextField id='outlined-basic'
							variant='outlined'
						 	value={this.props.registro.diasVega || 0}
							onChange={(event) => {

								let valor = parseInt((event.target.value || '0').replace(/\D/g, ''));

								this.props.initForm({
									...this.props.registro,
									diasVega: valor > 999 ? 999 : valor
								});
							}} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Flora (dias)
						</Typography>
						<TextField id='outlined-basic'
							variant='outlined'
						 	value={this.props.registro.diasFlora || 0}
							onChange={(event) => {

								let valor = parseInt((event.target.value || '0').replace(/\D/g, ''));

								this.props.initForm({
									...this.props.registro,
									diasFlora: valor > 999 ? 999 : valor
								});
							}} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Vega (%)
						</Typography>
						<Slider
							style={style.inputSlider}
							defaultValue={this.props.registro.canalUm || 0}
							aria-labelledby='input-slider'
							valueLabelDisplay='auto'
							marks={[{ value: this.props.registro.canalUm || 0, label: `${this.props.registro.canalUm || 0} %` }]}
							value={this.props.registro.canalUm || 0}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									canalUm: value || 0
								});
							}}
							step={1}
							min={0}
							max={100} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Flora (%)
						</Typography>
						<Slider
							style={style.inputSlider}
							defaultValue={this.props.registro.canalDois || 0}
							aria-labelledby='input-slider'
							valueLabelDisplay='auto'
							marks={[{ value: this.props.registro.canalDois || 0, label: `${this.props.registro.canalDois || 0} %` }]}
							value={this.props.registro.canalDois || 0}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									canalDois: value || 0
								});
							}}
							step={1}
							min={0}
							max={100} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Aviso de falta de água (dias)
						</Typography>
						<TextField id='outlined-basic'
							variant='outlined'
						 	value={this.props.registro.avisoAgua || 0}
							onChange={(event) => {

								let valor = parseInt((event.target.value || '0').replace(/\D/g, ''));

								this.props.initForm({
									...this.props.registro,
									avisoAgua: valor > 999 ? 999 : valor
								});
							}} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Velocidade máxima do ventilador em (°C)
						</Typography>
						<Slider
							style={style.inputSlider}
							defaultValue={this.props.registro.tempVento || 0}
							aria-labelledby='input-slider'
							valueLabelDisplay='auto'
							value={this.props.registro.tempVento || 0}
							marks={[{ value: this.props.registro.tempVento || 0, label: `${this.props.registro.tempVento || 0} °C` }]}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									tempVento: value || 0
								});
							}}
							step={1}
							min={0}
							max={50} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Ventilação Constante
						</Typography>{console.log(this.props.registro.ventilacaoConstante)}
						<Switch
							color='primary'
							checked={this.props.registro.ventilacaoConstante}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									ventilacaoConstante: value || false
								});
							}} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Definir Início Hoje
						</Typography>
						<Switch
							color='primary'
							checked={this.props.registro.inicioHoje}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									inicioHoje: value || false
								});
							}} />
					</Grid>

				</Grid>

				<Grid container
					style={style.gridButton}
					direction='row'
					justifyContent='center'>

					<Button
						style={{ marginRight: 5 }}
						variant='outlined'
						color='primary'
						onClick={() => this.props.salvar(this.props.registro)} color='primary'>
						Salvar
					</Button>
					<Button
						style={{ marginLeft: 5 }}
						variant='outlined'
						color='primary'
						onClick={() => this.props.setModoTela('dashboard')} color='secondary'>
						Voltar
					</Button>
				</Grid>

			</Grid>
        )
    }


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.configuracao.registro || {}
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getDados, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracaoForm);

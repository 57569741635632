import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'DISPOSITIVO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('dispositivoForm', registro)
    ];
}

export function getLista(id_usuario) {
    const request = axios.get(`${consts.API_URL}/dispositivo?id_usuario=${id_usuario}`);
    return {
        type: 'DISPOSITIVO_LISTADO',
        payload: request
    };
}

export function setDispositivoSelecionado(dispositivo) {
    return {
        type: 'DISPOSITIVO_SELECIONADO',
        payload: dispositivo
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/dispositivo`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('cadastro'));
				dispatch(initForm({
					...registro,
					...resp.data
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/dispositivo`, {
				...registro,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {

		if (!getState().auth.usuario.administrador) {
			axios.put(`${consts.API_URL}/dispositivo`, {
				...registro,
				id_usuario: null,
				nome: null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(setDispositivoSelecionado(null));
				dispatch(getLista(registro.id_usuario));
				window.location.reload();
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/dispositivo`, {
				...registro,
				id_usuario_administrador: null,
				nome_administrador: null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(setDispositivoSelecionado(null));
				dispatch(getLista(registro.id_usuario));
				window.location.reload();
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

const INITIAL_STATE = {
	registro: {
		canalUm: 0,
		canalDois: 0
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'MANUAL_SET_REGISTRO':
            return { ...state, registro: action.payload.registro };

		case 'MANUAL_LISTADO':

			let data = action.payload.data;

			let registro = null;
			if (data) {
				registro = {};

				registro.canalUm = data.canalUm ? Math.round((data.canalUm * 100) / 1023) : 0;
				registro.canalDois = data.canalDois ? Math.round((data.canalDois * 100) / 1023) : 0;

				console.log(data);

			}

            return {
				...state,
				registro: registro || INITIAL_STATE.registro
			};


        default:
            return state;
    }
}

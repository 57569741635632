import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	AppBar, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, Snackbar,
	IconButton, Badge, Dialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions, DialogContentText, DialogTitle, DialogContent, DialogActions,
	List, ListItem, ListItemAvatar, ListItemText, Divider, Card, CardContent, Paper, BottomNavigation, BottomNavigationAction, Slider, Switch, makeStyles
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import DateFormat from '../common/dateFormat/DateFormat';

import { setModoTela } from '../dashboard/dashboardActions';
import { initForm, getDados, salvar } from './manualActions';

class ManualForm extends Component {

    componentWillMount() {
		setTimeout(() => {
			this.props.getDados();
		}, 1000);
    }

	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100
			},
			gridInput: {
				marginTop: 16,
				paddingLeft: 16,
				paddingRight: 16
			},
			inputSlider: {
			},
			gridButton: {
				marginTop: 20
			}
		};
	}

    render() {

		let style = this.getLocalStyles();

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<Grid container
				spacing={1}
				style={style.root}
				direction='column'
				justifyContent='center'
				alignItems='center' >

				<Grid container
					direction='row'
					justifyContent='left'
					xs={12} sm={12} md={10} lg={8} >

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Vega (%)
						</Typography>
						<Slider
							style={style.inputSlider}
							defaultValue={this.props.registro.canalUm || 0}
							aria-labelledby='input-slider'
							valueLabelDisplay='auto'
							marks={[{ value: this.props.registro.canalUm || 0, label: `${this.props.registro.canalUm || 0} %` }]}
							value={this.props.registro.canalUm || 0}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									canalUm: value || 0
								});
								this.props.salvar({
									...this.props.registro,
									canalUm: value || 0
								});
							}}
							step={1}
							min={0}
							max={100} />
					</Grid>

					<Grid container
						style={style.gridInput}
						direction='column'
						justifyContent='left' >

						<Typography id='discrete-slider' gutterBottom variant='body1'>
							Flora (%)
						</Typography>
						<Slider
							style={style.inputSlider}
							defaultValue={this.props.registro.canalDois || 0}
							aria-labelledby='input-slider'
							valueLabelDisplay='auto'
							marks={[{ value: this.props.registro.canalDois || 0, label: `${this.props.registro.canalDois || 0} %` }]}
							value={this.props.registro.canalDois || 0}
							onChange={(event, value) => {
								this.props.initForm({
									...this.props.registro,
									canalDois: value || 0
								});
								this.props.salvar({
									...this.props.registro,
									canalDois: value || 0
								});
							}}
							step={1}
							min={0}
							max={100} />
					</Grid>

				</Grid>

			</Grid>
        )
    }


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.manual.registro || {}
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getDados, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManualForm);
